@import 'react-toastify/dist/ReactToastify.css';

// Overwrite library classes
.Toastify__toast-container--top-right {
    top: 2vh;
    right: 2vh;
}
.Toastify__toast-container {
    padding: 0;
    width: 19%;
}
.Toastify__toast {
    display: block;
    min-height: initial;
    max-height: initial;
    margin: 0 0 2vh 0;
    padding: 1.5vh 2vh;
    border-radius: 5px;
    border-left: 10px solid rgba(0, 0, 0, 0.1);
}
.Toastify__toast-body {
    padding: 0;
}
.Toastify__progress-bar {
    position: initial;
    margin: 1.5vh 0 0 0;
    border-radius: 10px;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: orange;
}
