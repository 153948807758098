a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
html, body, div, span, applet, object, iframe, main,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, input, select, textarea,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, svg {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none !important;
    outline-color: transparent !important;
}

a {
    text-decoration: none;
}

:focus {
    outline: none !important;
    outline-color: transparent !important;
    outline-style: none !important;
    outline-width: 0 !important;
}

#root {
    display: flex;
    position: relative;
    min-height: 100vh;
}

@font-face {
    font-family: 'RadioCanada';
    src: url('../fonts/RadioCanada/Radio-Canada-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'RadioCanada';
    src: url('../fonts/RadioCanada/Radio-Canada-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'RadioCanada';
    src: url('../fonts/RadioCanada/Radio-Canada-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'RadioCanada';
    src: url('../fonts/RadioCanada/Radio-Canada-SemiBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'RadioCanada';
    src: url('../fonts/RadioCanada/Radio-Canada-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Redacted';
    src: url('../fonts/Redacted/Redacted-Normal.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
