// Import files relatively from node_modules folder
@import 'ag-grid-community/dist/styles/ag-grid.scss';
@import 'ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';
@import 'ag-grid-community/dist/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin.scss';

// Define customization const
$borderRadius: 4px;
$headerFontSize: 15px;
$headerResizeRatio: 55%;
$nothingToShowSize: 1.5rem;

.ag-theme-alpine-light {
    // Extends given alpine theme
    @include ag-theme-alpine((
        // Tweak some params
        // @see https://www.ag-grid.com/react-data-grid/themes-customising/#full-list-of-theme-parameters
        font-family: 'RadioCanada',
        input-focus-box-shadow: null,
        cell-horizontal-border: null,
        header-column-resize-handle-height: $headerResizeRatio,
    ));
    // Border radius of the grid
    .ag-root-wrapper {
        border-radius: $borderRadius;
    }
    // Header content is extrabold and bigger
    .ag-header {
        font-size: $headerFontSize;
    }
    .ag-cell-wrapper {
        height: 100%;
    }
    // No border on focused cells
    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
        border-color: transparent;
    }
    // Always a right border on left pinned cell
    // @check src/configs/dataGrid
    .leftPinnedCell {
        border-right-color: ag-param(border-color) !important;
    }
    // Remove border on bottom horizontal scrollbar
    .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
        border-color: transparent;
    }
    // Pointer cursor on checkboxes
    .ag-checkbox-input {
        cursor: pointer;
    }
    // Text when there is nothing to show
    .ag-overlay-no-rows-center {
        font-size: $nothingToShowSize;
    }
}

// Same for dark theme
.ag-theme-alpine-dark {
    @include ag-theme-alpine-dark((
        font-family: 'RadioCanada',
        input-focus-box-shadow: null,
        cell-horizontal-border: null,
        header-column-resize-handle-height: $headerResizeRatio,
    ));
    .ag-root-wrapper {
        border-radius: $borderRadius;
        border-color: transparent;
    }
    .ag-header {
        font-size: $headerFontSize;
    }
    .ag-cell-wrapper {
        height: 100%;
    }
    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
        border-color: transparent;
    }
    .leftPinnedCell {
        border-right-color: ag-param(border-color) !important;
    }
    .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
        border-color: transparent;
    }
    .ag-checkbox-input {
        cursor: pointer;
    }
    .ag-overlay-no-rows-center {
        font-size: $nothingToShowSize;
    }
}
