.toolsMenuPage {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: fit-content;
    margin: 0 auto;
    @media(max-width: 1528px){
        grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width: 1108px){
        grid-template-columns: repeat(1, 1fr);
    }
};
